<style scoped>
div {
  background: rgba(0, 0, 0, 0) !important;
}
</style>

<template>
  <AppButtonCollapse
    icon="mdi-book-open-page-variant-outline"
    messageHidden="Literatur anzeigen"
    messageNotHidden="Literatur verstecken"
    elevation="0"
  >
    <div>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>
                Gaidoschik, M. (2022). Einmaleins verstehen, vernetzen, merken.
                Strategien gegen Lernschwierigkeiten. 6. Aufl. Seelze: Klett
                Kallmeyer
              </td>
            </tr>
            <tr>
              <td>
                Götze, D., Selter, Ch. & Zannetin, E. (2019). Das KIRA-Buch:
                Kinder rechnen anders. Verstehen und Fördern im
                Mathematikunterricht. Hannover: Klett, Kallmeyer.
              </td>
            </tr>
            <tr>
              <td>
                Kuhnke, K. (2012). Vorgehensweisen von Grundschulkindern beim
                Darstellungswechsel. Eine Untersuchung am Beispiel der
                Multiplikation im 2. Schuljahr. Heidelberg: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Ministerium für Schule und Bildung des Landes
                Nordrhein-Westfalen (2021). Lehrplan Mathematik. In Ministerium
                für Schule und Bildung des Landes Nordrhein-Westfalen (Hrsg.).
                Lehrpläne für die Primarstufe in Nordrhein- Westfalen.
                https://www.schulentwicklung.nrw.de/lehrplaene/upload/klp_PS/ps_lp_sammel-band_2021_08_02.pdf
              </td>
            </tr>
            <tr>
              <td>
                Padberg, F. & Benz, Ch. (2021). Didaktik der Arithmetik. Für
                Lehrerausbildung und Lehrerfortbildung. 5. überarbeitete Aufl.
                Heidelberg: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Prediger, S. (2020). Multiplizieren verstehen und erklären –
                Sprachbildendes Fördermaterial. Didaktischer Kommentar zum
                sprachbildenden Fördermaterial. Open Educational Resources.
                https://sima.dzlm.de/unterricht/unterrichtsmaterialien-sekundarstufe
              </td>
            </tr>
            <tr>
              <td>
                Selter, Ch. & Zannetin, E. (2018). Mathematik unterrichten in
                der Grundschule. Inhalte – Leitideen – Beispiele. Seelze: Klett
                Kallmeyer.
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </AppButtonCollapse>
</template>

<script>
import AppButtonCollapse from "@/common/AppButtonCollapse";

export default {
  name: "AppLiteraturEME",
  components: {
    AppButtonCollapse,
  },
};
</script>
