<template>
  <div>
    <h2 class="faledia-caption">Kernaufgaben</h2>
    <v-card outlined>
      <v-card-title>Was sollen die Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können...
        <ul>
          <li>
            einfachere Aufgaben (Kernaufgaben) und schwierigere Aufgaben
            unterscheiden,
          </li>
          <li>die Ergebnisse der Kernaufgaben automatisiert wiedergeben und</li>
          <li>Kernaufgaben an der Einmaleinstafel wiederfinden.</li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <p>
      Die Erarbeitung des kleinen Einmaleins ist ganzheitlich zu denken. Das
      bedeutet, dass die einzelnen sogenannten „Reihen“ des kleinen Einmaleins
      nicht isoliert voneinander auswendig gelernt, sondern die Aufgaben –
      ausgehend von sogenannten Kernaufgaben – unter Ausnutzung von Beziehungen
      erschlossen werden. Ein isoliertes Aneignen der Einmaleinsreihen – in Form
      von bloßem Auswendiglernen aller 121 Aufgaben und deren Ergebnissen – ist
      mit einem großen Zeit- und Müheaufwand verbunden (Gaidoschik, 2022) und
      für die gelingende Aneignung vieler weiterer Inhalte nicht zielführend.
      Für eine langfristige Automatisierung des kleinen Einmaleins ist es
      zentral, die Aufgaben zu vernetzen, „denn je stärker eine Aufgabe mit
      anderen Aufgaben vernetzt abgespeichert wird, umso größer ist die
      Wahrscheinlichkeit, dass sie jederzeit wieder abgerufen werden kann“
      (Götze, Selter & Zannetin, 2019, S. 77).
    </p>
    <p>
      Kernaufgaben stellen dabei Aufgaben dar, die Kinder sich in der Regel
      leicht merken und aufgrund ihrer besonderen Eigenschaften schnell
      automatisieren können. Sie können als Ausgangspunkt zur Erarbeitung und
      Herleitung anderer Aufgaben genutzt werden. Für eine Erarbeitung des
      kleinen Einmaleins mit Hilfe von Kernaufgaben ist es nötig, dass die
      Lernenden das kleine Einspluseins (siehe auch
      <a href="/einspluseins">Einspluseins</a>) und das kleine Einsminuseins
      sicher beherrschen und zudem im Hunderterraum sicher rechnen können
      (Gaidoschik, 2022). Außerdem sollten die Lernenden über ein gesichertes
      Operationsverständnis zu den Grundrechenarten, insbesondere auch zur
      Multiplikation, verfügen.
    </p>
    <p>
      Die folgende Tabelle zeigt auf, welche Aufgaben des Einmaleins als
      Kernaufgaben angesehen werden (vgl. Gaidoschik, 2022, Padberg & Benz,
      2021; Götze, et al., 2019).
    </p>
    <p><strong>Kernaufgaben:</strong></p>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td><strong>Kernaufgabe</strong></td>
            <td>Beispiele</td>
          </tr>
          <tr>
            <td><strong>Malaufgaben mit 1</strong></td>
            <td>1 · 2, 1 · 4, 1 · 7</td>
          </tr>
          <tr>
            <td><strong>Malaufgaben mit 10</strong></td>
            <td>1 · 10, 2 · 10, 6 · 10</td>
          </tr>
          <tr>
            <td>
              <strong
                >Verdopplungsaufgaben <br />
                (Malaufgaben mit 2)
              </strong>
            </td>
            <td>2 · 4, 2 · 6, 2 · 8</td>
          </tr>
          <tr>
            <td><strong>Malaufgaben mit 5</strong></td>
            <td>5 · 1, 5 · 3, 5 · 6</td>
          </tr>
          <tr>
            <td><strong>Quadratzahlaufgaben</strong></td>
            <td>2 · 2, 5 · 5, 7 · 7</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p>
      Die Einmaleinstafel stellt die Aufgaben des kleinen Einmaleins mit den
      Produkten zwischen 0 und 10 auf geordnete Weise dar. Sie kann auch dazu
      genutzt werden, um Kindern bewusst zu machen, dass es schon einige
      Aufgaben gibt, die sie schnell und sicher beherrschen. Durch das
      sukzessive Markieren von Aufgaben die ihnen leichter fallen (der
      Kernaufgaben), wird auch visuell deutlich, dass es einerseits gar nicht so
      viele ‚schwierige‘ Aufgaben des kleinen Einmaleins gibt und andererseits,
      dass diese Aufgaben stets in der Nähe einer leicht zu lösenden oder
      bereits automatisierten Aufgabe liegen. Zudem kann die Tafel auch als
      Checkliste für die Kinder herangezogen werden, um bereits automatisierte
      Aufgaben individuell kenntlich zu machen (Gaidoschik, 2022).
    </p>
    <p>
      An der Einmaleinstafel können Sie sich die einzelnen Kernaufgaben farblich
      markiert anzeigen lassen. Werden alle Kernaufgaben markiert angezeigt, so
      ist zu sehen, dass nur ungefärbte Felder in der Nähe zu Kernaufgaben
      übrigbleiben. Über diese Nähe zu Kernaufgaben können die Ergebnisse über
      Ableitungsstrategien hergeleitet werden.
    </p>
    <v-card elevation="0" style="width: 90%; margin-inline: auto;">
      <v-card-title>Einmaleinstafel</v-card-title>
      <v-card-text>
        <v-row>
          <v-col align-self="center">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td
                      class="flexEinspluseins eineZeile"
                      @click="
                        changeImage(require('@/assets/eme/Tafel/keine.png'))
                      "
                    >
                      Keine
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins eineZeile"
                      @click="changeImage(require('@/assets/eme/Tafel/0.png'))"
                    >
                      Faktor 0
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins eineZeile"
                      @click="changeImage(require('@/assets/eme/Tafel/1.png'))"
                    >
                      Faktor 1
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins eineZeile"
                      @click="changeImage(require('@/assets/eme/Tafel/10.png'))"
                    >
                      Faktor 10
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins eineZeile"
                      @click="changeImage(require('@/assets/eme/Tafel/5.png'))"
                    >
                      Faktor 5
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins zweiZeilen"
                      @click="
                        changeImage(
                          require('@/assets/eme/Tafel/Quadrataufgaben.png')
                        )
                      "
                    >
                      Quadrataufgaben
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins zweiZeilen"
                      @click="
                        changeImage(
                          require('@/assets/eme/Tafel/Verdopplungsaufgaben.png')
                        )
                      "
                    >
                      Verdopplungsaufgaben
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="flexEinspluseins eineZeile"
                      @click="
                        changeImage(require('@/assets/eme/Tafel/alle.png'))
                      "
                    >
                      Alle
                      <v-btn small elevation="0">&rarr;</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col align-self="center" style="max-width: 60%;">
            <AppExpandableImage
              contain
              :src="this.einspluseinstafelBild"
              elementId="einspluseinstafel.png"
              parentId="epe"
            >
            </AppExpandableImage>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <br />
    <AppLiteraturEME />
    <AppBottomNavEME
      back="/einmaleins/hintergrundwissen"
      next="/einmaleins/hintergrundwissen/ableitungsstrategien"
    />
  </div>
</template>

<script>
import AppBottomNavEME from "@/common/AppBottomNavEME";
import AppLiteraturEME from "@/common/AppLiteraturEME";
import AppExpandableImage from "@/common/AppExpandableImage";

export default {
  components: {
    AppBottomNavEME,
    AppLiteraturEME,
    AppExpandableImage,
  },
  data: () => ({
    einspluseinstafelBild: require("@/assets/eme/Tafel/keine.png"),
  }),
  methods: {
    changeImage: function(image) {
      this.einspluseinstafelBild = image;
    },
  },
};
</script>

<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
.image {
  max-width: 50%;
  margin-inline: auto;
  background-color: white;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-block-start: 5%;
}
.flexEinspluseins {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-block-start: 7px;
  padding: 10px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.eineZeile {
  padding: 2.5%;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.zweiZeilen {
  padding-block: 0;
  padding-inline: 2.5%;
}
</style>
